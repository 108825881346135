<template>
  <div class="flexdc flex1" style="height: 100%">
    <div class="operationControl flexdc" style="align-items: flex-start">
      <div class="searchbox" style="margin-bottom: 10px">
        <div title="课程名称" class="searchboxItem">
          <span class="itemLabel">课件名称:</span>
          <el-input
            clearable
            v-model="searchData.kpointName"
            type="text"
            size="small"
            placeholder="请输入课件名称"
          />
        </div>
        <div title="培训类型" class="searchboxItem">
          <span class="itemLabel">培训类型:</span>
          <el-cascader
                  v-model="searchData.trainTypeId"
                  :options="TrainTypeList"
                  filterable
                  clearable
                  :show-all-levels="false"
                  :props="{
                    emitPath: false,
                    value: 'id',
                    label: 'label',
                    children: 'children',
                    checkStrictly: true,
                  }"
                  size="small"
                ></el-cascader>
        </div>
        <div title="上传时间" class="searchboxItem">
          <span class="itemLabel">上传时间:</span>
          <el-date-picker
            v-model="searchData.uploadTime"
            type="daterange"
            size="small"
            range-separator="至"
             value-format="yyyy-MM-dd"
            value="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
        <div>
          <el-button class="bgc-bv" round @click="getData()" size="small"
            >查询</el-button
          >
          <el-button
            class="bgc-bv"
            round
            @click="handleCourseWare('add')"
            size="small"
            >上传新课件</el-button
          >
        </div>
      </div>
     
    </div>
    <div class="framePage-scroll">
      <el-table
        ref="multipleTable"
        :data="tableData"
        :height="tableHeight"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="tableHeader"
        stripe
      >
        <el-table-column
          label="序号"
          align="center"
          type="index"
          fixed
          :index="indexMethod"
        />
        <el-table-column label="课件名称" align="left" prop="kpointName" width="220">
          <template slot-scope="scope">
            {{ scope.row.kpointName }}
          </template>
        </el-table-column>
        <el-table-column label="培训类型" align="left" prop="categoryName">
          <template slot-scope="scope">
            {{ scope.row.categoryName.split('##')[0] }}
          </template>
        </el-table-column>
        <el-table-column label="视频时长" align="left" prop="kpointDuration">
          <template slot-scope="scope">
            {{ getTime(scope.row.kpointDuration)  }}
          </template>
        </el-table-column>
        <el-table-column label="课件类型" align="left" prop="kpointType">
          <template slot-scope="scope">
            {{ $setDictionary('RESOURCETYPE',scope.row.kpointType) }}
          </template>
        </el-table-column>
        <el-table-column label="引用次数" align="left" prop="quoteNum">
          <template slot-scope="scope">
            {{ scope.row.quoteNum }}
          </template>
        </el-table-column>
        <el-table-column label="上传时间" align="left" prop="createTime">
          <template slot-scope="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" fixed="right">
          <div slot-scope="scope" class="df">
            <el-button
              style="padding: 0 5px"
              type="text"
              size="mini"
              @click="handleCourseWare('edit', scope.row.kpointId)"
              >查看</el-button
            >
            <el-button
              style="padding: 0 5px"
              type="text"
              size="mini"
              @click="handleDel(scope.row.kpointId)"
              >删除</el-button
            >
          </div>
        </el-table-column>
        <Empty slot="empty" />
      </el-table>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //搜索条件
      searchData: {
        kpointName: "", //课件名称
        trainTypeId: "", //培训类型
        uploadTime: "", //上传时间
      },
      TrainTypeList:[], //培训类型数据
    };
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  created() {
    this.getTrainTypeArrData()
  },
  methods: {
     //获取培训类型数据
    getTrainTypeArrData() {
      this.$post("/sys/category/train-type/enterprise", {}).then((ret) => {
        this.TrainTypeList = ret.data;
      });
    },
    // 获取数据列表
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.kpointName) {
        params.kpointName = this.searchData.kpointName;
      }
      if (this.searchData.trainTypeId) {
        params.trainTypeId = this.searchData.trainTypeId;
      }
      if (this.searchData.uploadTime) {
        params.startDate = this.searchData.uploadTime[0];
        params.endDate = this.searchData.uploadTime[1];
      }

      this.doFetch({
        url: "/biz/et/courseware/page",
        params,
        pageNum,
      });
    },
    // 上传&&查看 -- 课件
    handleCourseWare(stu, kpointId = "") {
      this.$router.push({
        path: "/web/CustomPrograms/enterpriseInternalTrainingUploadCourseWare",
        query: {
          stu,
          kpointId,
        },
      });
    },
    // 删除
    handleDel(kpointId) {
      this.doDel({
        url: "/biz/courseware/modifyDeleted",
        msg: "你确定要删除该课件吗？",
        ps: {
          type: "post",
          data: { kpointId, deleted: 1, },
        },
      });
    },
    // 获取列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 2) * 16;
      if (opDom) {
        tHeight -= 90 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    //时间转换 秒转时分秒
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
};
</script>

