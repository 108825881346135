<template>
  <div class="flexdc flex1" style="height: 100%">
    <div class="operationControl flexdc" style="align-items: flex-start">
      <div class="searchbox" style="margin-bottom: 10px">
        <div title="课程名称" class="searchboxItem">
          <span class="itemLabel">课程名称:</span>
          <el-input
            clearable
            v-model="searchData.courseName"
            type="text"
            size="small"
            placeholder="请输入课程名称"
          />
        </div>
        <div title="培训类型" class="searchboxItem">
          <span class="itemLabel">培训类型:</span>
          <el-cascader
            v-model="searchData.trainTypeId"
            :options="TrainTypeList"
            filterable
            clearable
            :show-all-levels="false"
            :props="{
              emitPath: false,
              value: 'id',
              label: 'label',
              children: 'children',
              checkStrictly: true,
            }"
            size="small"
          ></el-cascader>
        </div>
        <div title="课程来源" class="searchboxItem ci-full">
          <span class="itemLabel">课程来源:</span>
          <el-select
            v-model="searchData.courseOrigin"
            placeholder="请选择课程来源"
            clearable
            size="small"
          >
            <el-option label="自有" value="10"></el-option>
            <el-option label="平台" value="20"></el-option>
          </el-select>
        </div>
      </div>
      <div class="searchbox">
        <div title="创建时间" class="searchboxItem">
          <span class="itemLabel">创建时间:</span>
          <el-date-picker
            v-model="searchData.createTime"
            type="daterange"
             size="small"
            range-separator="至"
            value-format="yyyy-MM-dd"
            value="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
        <div>
          <el-button class="bgc-bv" round @click="getData()" size="small"
            >查询</el-button
          >
          <el-button class="bgc-bv" round @click="handleAdd()" size="small"
            >创建课程</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <el-table
        ref="multipleTable"
        :data="tableData"
        :height="tableHeight"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="tableHeader"
        stripe
      >
        <el-table-column
          label="序号"
          align="center"
          type="index"
          fixed
          :index="indexMethod"
        />
        <el-table-column
          label="课程名称"
          align="left"
          prop="courseName"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.courseName }}
          </template>
        </el-table-column>
        <el-table-column label="培训类型" align="left" prop="categoryName" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.categoryName.split("##")[0] }}
          </template>
        </el-table-column>
        <el-table-column label="课件数量" align="left" prop="coursewareNum">
          <template slot-scope="scope">
            {{ scope.row.coursewareNum }}
          </template>
        </el-table-column>
        <el-table-column label="视频总时长" align="left" prop="totalDuration">
          <template slot-scope="scope">
            {{ getTime(scope.row.totalDuration) }}
          </template>
        </el-table-column>
        <el-table-column label="课程来源" align="left" prop="courseOrigin">
          <template slot-scope="scope">
            {{ $setDictionary("COURSEORGIN", scope.row.courseOrigin) }}
          </template>
        </el-table-column>
        <el-table-column label="引用次数" align="left" prop="quoteNum">
          <template slot-scope="scope">
            {{ scope.row.quoteNum }}
          </template>
        </el-table-column>
        <el-table-column label="发布状态" align="left" prop="publishStatus">
          <template slot-scope="scope">
            {{ scope.row.publishStatus }}
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          align="left"
          prop="createTime"
          min-width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          align="center"
          width="220px"
          fixed="right"
        >
          <div slot-scope="scope" class="df">
            <el-button
              style="padding: 0 5px"
              type="text"
              size="mini"
              @click="handleEdit(scope.row)"
              >查看</el-button
            >
            <el-button
              style="padding: 0 5px"
              type="text"
              size="mini"
              :disabled="scope.row.courseOrigin == '20'"
              @click="handleDel(scope.row.courseId)"
              >删除</el-button
            >
            <el-button
              style="padding: 0 5px"
              :disabled="scope.row.courseOrigin == '20'"
              type="text"
              size="mini"
              @click="handleCopy(scope.row.courseId)"
              >复制课程</el-button
            >
            <el-button
              style="padding: 0 5px"
              type="text"
              size="mini"
              :disabled="scope.row.courseOrigin == '20'"
              @click="
                handlePush(scope.row.courseId, scope.row.auditState)
              "
            >
              {{ scope.row.auditState === "30" ? "撤销" : "提交" }}发布
            </el-button>
          </div>
        </el-table-column>
        <Empty slot="empty" />
      </el-table>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <CurriculumCopyPop ref="CurriculumCopyPop" @eventbtn="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import CurriculumCopyPop from "@/views/resourse/popup/CurriculumCopyPop";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "enterpriseInternalTrainingMyCourse",
  components: {
    Empty,
    PageNum,
    CurriculumCopyPop,
  },
  mixins: [List],
  data() {
    return {
      searchData: {
        courseName: "", //课程名称
        courseOrigin: "", //课程来源
        trainTypeId: "", //培训类型
        createTime: "", //创建时间
      },
      TrainTypeList: [],
      activeName: "first",
    };
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  created() {
    this.getTrainTypeArrData();
  },
  methods: {
    //获取培训类型数据
    getTrainTypeArrData() {
      this.$post("/sys/category/train-type/enterprise", {}).then((ret) => {
        this.TrainTypeList = ret.data;
      });
    },
    //初始化获取列表数据
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.courseName) {
        params.courseName = this.searchData.courseName;
      }
      if (this.searchData.courseOrigin) {
        params.courseOrigin = this.searchData.courseOrigin;
      }
      if (this.searchData.trainTypeId) {
        params.trainTypeId = this.searchData.trainTypeId;
      }
      if (this.searchData.createTime) {
        params.startDate = this.searchData.createTime[0];
        params.endDate = this.searchData.createTime[1];
      }
      this.doFetch({
        url: "/biz/et/course/page",
        params,
        pageNum,
      });
    },
    // 创建课程
    handleAdd() {
      this.$router.push({
        path: "/web/CustomPrograms/enterpriseInternalTrainingUploadMyCourse",
      });
    },
    // 修改课程
    handleEdit(row) {
      let path = "/web/CustomPrograms/enterpriseInternalTrainingLookMyCourse";
      this.$router.push({
        path,
        query: {
          courseId: row.courseId,
          courseOrigin: row.courseOrigin,
          auditState: row.auditState,
          stu: "edit",
        },
      });
    },
    // 复制课程
    handleCopy(courseId) {
      this.$refs.CurriculumCopyPop.showPopUp(courseId);
    },
    // 删除
    handleDel(courseId) {
      this.doDel({
        url: "/biz/course/delete",
        msg: "你确定要删除该课程吗？",
        ps: {
          type: "post",
          data: { courseId: courseId },
        },
      });
    },
    //获取高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 2) * 16;
      if (opDom) {
        tHeight -= 90 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    //发布
    handlePush(courseId,auditState) {
      this.$post('/biz/et/course/publish',{courseId,targetState:auditState == '10'?'30':'10'}).then(res => {
        if (res.status == 0) { 
          this.$message.success(res.message);
          this.getData(-1)
        }
      })
    },
    //时间转换
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },

};
</script>

