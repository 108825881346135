<template>
  <div class="pageContol listWrap ">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">企业内训</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">我的课程/课件</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs
          v-model="activeName"
          :before-leave="beforeTabLeave"
          @tab-click="tabChange"
        >
          <el-tab-pane label="我的课程" name="first">
            <enterpriseInternalTrainingMyCourse ref="first" />
          </el-tab-pane>
          <el-tab-pane label="我的课件" name="second">
            <enterpriseInternalTrainingMyCourseWare ref="second" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import enterpriseInternalTrainingMyCourse from "@/views/CustomPrograms/myCourseCourseWare/enterpriseInternalTrainingMyCourse.vue"; //我的课程
import enterpriseInternalTrainingMyCourseWare from "@/views/CustomPrograms/myCourseCourseWare/enterpriseInternalTrainingMyCourseWare.vue"; //我的课件
import { mapGetters } from "vuex";
export default {
  name: "CustomPrograms/enterpriseInternalTrainingMyCourseCourseWare",
  components: {
    enterpriseInternalTrainingMyCourse,
    enterpriseInternalTrainingMyCourseWare
  },
  mixins: [List],
  data() {
    return {
      activeName: "first", //我的课程/课件tab名
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    tabChange(tab) {
      this.activeName = tab.name;
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName },
      });
    },
  },
    watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh || val.query.refresh == "true") {
          this.$refs[val.query.active].getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" >
// .framePage-tabs {
//  /deep/.el-tabs {
//     flex: 1;
//     display: flex;
//     flex-direction: column;
//     /deep/.el-tabs__content {
//       flex: 1;
//     }
//   }
// }

</style>
